<template>
    <div class="header">
        <img class="logo" src="../assets/logo.png" alt="">
        <div class="user" >
            <div class="photo" @click="$router.push('/setUser')">
                <img class="photo" :src="url_prefix + userList.avatar" alt="" v-if="userList.avatar">
            </div>

            <span>
                    {{userList.nickname || "小数"}}
                </span>
            <div class="set">
                <img class="set-img" src="../assets/set.png" @click="$router.push('/setUser')">
                <ul class="set-ul">
                    <li
                            @click="setPassFn"
                            :class="setLi == 1?'setLiColor':''"
                    >
                        <p>密码修改</p>
                    </li>
                    <li
                            :class="setLi == 2?'setLiColor':''"
                            @click="quitLoginFn"
                    >
                        <p>退出登录</p>
                    </li>
                    <li></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "../lnit/api";

    export default {
        name: "header",
        data(){
            return{
                userList:{},
                url_prefix:null,
                setLi:1,
            }
        },
        created() {
            this.getUserFn()
            this.url_prefix = api.url_prefix
        },
        methods:{
            setPassFn(){
                this.setLi = 1
                this.$router.push('/setUser')
            },
            quitLoginFn(){
                // this.setLi = 2
                this.$router.replace('/login')
                localStorage.removeItem("token")
            },
            getUserFn(){
                this.$post({
                    url:api.get_user,
                }).then((res)=>{
                    this.userList = res.data.user
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        height: 80px;
        margin: 0 auto;
        background-color: #fff;
        .logo{
            height: 60px;
        }

        .user{
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            height: 80px;
            &:hover .set .set-ul{
                display: block;
            }
            .photo{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-right: 5px;
            }
            .set{
                margin-left: 20px;

                width: 25px;
                height: 25px;
                .set-img{
                    width: 100%;
                    height: 100%;
                }

                .setLiColor{
                    color: #4C94FF;
                }
                .set-ul{
                    display: none;
                    text-align: center;
                    position: absolute;
                    right: 0px;
                    top: 40px;
                    width: 110px;
                    list-style:none;
                    background-color: #fff;
                    padding: 20px 10px;
                    z-index: 100;
                    li{
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .photo{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: url("../assets/avatar.webp") no-repeat;
        background-size: cover;
    }
</style>
