<template>
    <div>
        <div class="detail">
            <div class="detail-title">
                <span>
                    余额明细
                </span>
            </div>
            <div style="display: flex">
                <tbody style="margin: 0 auto">
                    <tr>
                        <th class="tablerow2">时间</th>
                        <th class="tablerow2">内容</th>
                        <th class="tablerow2">金额动态</th>
                        <th class="tablerow2">余额</th>
                    </tr>
                    <tr v-for="(item,inndex) in detailsList">
                        <th class="tablerow1">{{item.create_time | dateFormat}}</th>
                        <th class="tablerow1">{{item.info}}</th>
                        <th class="tablerow1">
                           <p v-if="item.type == 1" style="color: #67c23a">
                               +{{item.change_money}}元
                           </p>
                            <p v-if="item.type == 2" style="color:#f56c6c">
                                -{{item.change_money}}元
                            </p>

                        </th>
                        <th class="tablerow1">{{item.after_money}}元</th>
                    </tr>
                </tbody>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 20px">
                <el-pagination
                        class='videoPage'
                        background
                        layout="prev, pager, next"
                        :total="videoTotal"
                        @current-change="sizeChange"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "detail",
        data(){
            return{
                videoTotal:0,
                page:1,
                detailsList:[],
            }
        },
        created() {
          this.detailListFn()
        },
        methods:{
            detailListFn(){
              this.$get({
                  url:api.money_details,
                  data:{
                      type:'wallet',
                      page:this.page,
                  }
              }).then((res)=>{
                  this.detailsList = res.data.list.data
                    this.videoTotal = res.data.list.total
              })
            },
            sizeChange(e){
                this.page = e
                this.detailListFn()
            }
        }
    }
</script>

<style scoped lang="scss">
    .detail{
        background-color: #fff;
        padding: 20px;
        .detail-title{
            font-size: 15px;
            margin-bottom: 30px;
            text-align: center;
            font-width: 600;
        }
        .tablerow2{
            word-break: keep-all;
            white-space: nowrap;
            /*color: #fff;*/
            text-align: center;
            min-width: 120px;
        }
        .tablerow1, .tablerow2 {
            border: 1px solid #fff;
            line-height: 23px;
            padding-right: 5px;
            padding-left: 5px;
        }
        .tablerow1{
            font-size: 13px;
            color: #888888;
            font-style: normal;
            font-weight: normal;
            line-height: 15px;
        }
    }
</style>
