<template>
  <div id="app">
    <index/>
    <router-view></router-view>
  </div>
</template>
<script>
  export default {

  }
</script>
<style  lang="less">
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  color: #2c3e50;*/
/*}*/
  body{
    margin: 0;
    padding: 0;
  }
</style>
