<template>
    <div>

        <div class="login-box">
            <img class="p-img" src="../assets/pk-bg1.png" alt="">
            <div style="width: 400px;">
                <div class="login-form">
                    <h4>账号登录</h4>
                    <el-input class="elInput" v-model="account" placeholder="请输入账号"></el-input>
                    <el-input type="password" class="elInput" v-model="password" placeholder="请输入账号密码"></el-input>
                    <button type="submit" class="login-btn" @click="openLoginFn">登录</button>
<!--                    <div style="margin-top:20px">-->
<!--                      <p>去注册</p>-->
<!--                    </div>-->

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "login",
        data(){
            return{
                account:null,
                password:null,
            }
        },
        created() {
          if(localStorage.getItem('token')){
             this.$router.replace('/user')
          }
        },
        methods:{
            openLoginFn(){
                if(!this.account || !this.password){
                    this.$message({
                        message: '请输入账号和密码',
                        type:'error'
                    })
                    return
                }
                this.$post({
                    url:api.login,
                    data:{
                        username:this.account,
                        password: this.password
                    }
                }).then((res)=>{
                    if(res.data.code == 0){
                        localStorage.setItem("token",res.data.token)
                        this.$router.replace('/user')
                    }
                })

            }
        }
    }
</script>

<style  lang="scss">
    .login-form {
        width: 360px;
        height: 363px;
        position: absolute;
        top: 50%;
        left: 80%;
        transform: translate(-80%,-50%);
        background-color: rgba(255,255,255,.2);
        text-align: center;
        padding-top: 40px;
        box-sizing: border-box;
    }
    .login-box{
        display: flex;
        align-items: center;
        /*width: 100vw;*/
        height: calc(100vh - 80px);
        background: url("../assets/banner1.jpg") no-repeat;
        background-size: cover;
        /*box-sizing: border-box;*/
        /*padding: 8px;*/
    }
    .login-form{
        display: flex;
        font-size: 20px;
        color: white;
        flex-direction: column;
        align-items: center;
    }
    .el-input__inner{
        background-color: rgba(0,0,0,.2)!important;
        color: white!important;
    }
     .login-form .elInput {
        width: 300px;
        height: 44px;
        border-radius: 3px;
        /*border: 1px solid rgba(255, 255, 255, 0.5);*/
        background-color:rgba(255,255,255,.1)!important;
        margin-bottom: 24px;
        color: white;
    }
     .el-input__inner::-webkit-input-placeholder{
         color: white!important;
     }
    .login-btn{
        width: 300px;
        line-height: 44px;
        background-color: #4C94FF;
        border:none;
        font-size: 16px;
        color: white;
        cursor: pointer;
    }
    .p-img{
        width: 460px;
        height: 86px;
        margin-left: 130px;
    }

</style>
