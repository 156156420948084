<template>
    <div>
        <div class="loading">
            <div class="loading-title">
                <span>
                    上传视频
                </span>
            </div>
            <div style="display: flex;">
                <el-form>
                    <el-form-item label="上传附件">
                        <el-upload
                                :file-list="fileListArr"
                                action="https://app.xysjzyx.com/api/common/upload_file"
                                accept=".png,.mp4"
                                :on-exceed="handleExceedFj"
                                :on-remove="handleRemoveFj"
                                :before-upload="beforeUpload"
                                ref="scfj"
                                :show-file-list="false"
                                multiple
                                :limit="taskDetails.nums"
                                drag
                                :auto-upload="false"
                                :on-change="onChangeFj"
                                :http-request="uploadFile"
                        >
                        </el-upload>
                        <el-button
                                style="margin-left: 10px;"
                                size="small"
                                type="success"
                                @click="uploadFj">上传视频
                        </el-button>
                        <el-button
                            size="small"
                            @click="saveVideoFn"
                        >
                            保存视频
                        </el-button>
                        <el-button
                            size="small"
                            @click="refreshVideoFn"
                        >
                            刷新重传
                        </el-button>
                    </el-form-item>
                </el-form>
                <div class="goodsImg">
                    <img  :src="preFix + taskDetails.goods.img" alt="" >
                </div>
                <div class="title">
                    <p>{{taskDetails.goods.title}}</p>
                    <p>视频数量:{{taskDetails.nums}}</p>
                    <p>商品价格:{{taskDetails.goods.price}}元</p>
                </div>
<!--                <div style="height: 200px;">-->
<!--                    <div class="fileList">-->
<!--                        <tr class="fileList-tr" v-if="fileListArr.length">-->
<!--                            <th>-->
<!--                                <p>类名</p>-->
<!--                            </th>-->
<!--                            <th>-->
<!--                                <p>大小</p>-->
<!--                            </th>-->
<!--                            <th>-->
<!--                                <p>状态</p>-->
<!--                            </th>-->
<!--                            <th>-->
<!--                                <p>重新上传</p>-->
<!--                            </th>-->
<!--                        </tr>-->
<!--                        <tr class="fileList-tr" v-for="(item,index) in fileListArr">-->
<!--                            <td>{{item.name}}</td>-->
<!--                            <td>{{(item.size/1024/1024).toFixed(1)}}MB</td>-->
<!--                            <td :class="item.status == '上传成功'?'winColor':''">{{item.status}}</td>-->
<!--                            <td style="display: flex;align-items: center">-->
<!--                                <el-upload-->
<!--                                        action="https://gz.zxw233.com/api/common/upload_file"-->
<!--                                        :show-file-list="false"-->
<!--                                        :file-list="fileList"-->
<!--                                        :auto-upload="false"-->
<!--                                        :on-change="onChangeFn"-->
<!--                                >-->
<!--                                    <el-button size="small" type="primary" @click="onProgress(index)">点击上传</el-button>-->
<!--                                </el-upload>-->
<!--                                <el-button class="elBtn" slot="trigger" size="small" type="danger" @click="again(index)"-->
<!--                                           v-if="index == 1">重新上传-->
<!--                                </el-button>-->
<!--                            </td>-->
<!--                        </tr>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <el-main>
                <el-table :data="fileListArr"  max-height="500">
                    <el-table-column prop="name" :label="'文件名(' +fileListArr.length+')'" width="300">
                    </el-table-column>
                    <el-table-column prop="size" label="大小" width="200">
                        <template slot-scope="scope">
                            <div>
                                <p>{{(scope.row.size/1024/1024).toFixed(1)}}MB</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="200">
                        <template slot-scope="scope">
                            <div>
                                <p :class="scope.row.status == '上传成功'?'winColor':''">{{scope.row.status}}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="200">
                        <template slot-scope="scope">
                            <div style="display: flex" v-if="taskStatus == 3">
                                <el-upload
                                        action="https://gz.zxw233.com/admin/sys/up_banner"
                                        :show-file-list="false"
                                        :file-list="fileList"
                                        accept=".png,.mp4,.jpg"
                                        :auto-upload="false"
                                        :on-change="onChangeFn"
                                >
                                    <el-button size="small" type="primary" @click="onProgress(scope.$index)">点击上传</el-button>
                                </el-upload>
                                <el-button class="elBtn" slot="trigger" size="small" type="danger" @click="again(scope.$index)"
                                           v-if="scope.$index == 1">重新上传
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-main>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import api from '../lnit/api'
    export default {
        name: "loading",
        data() {
            return {
                centerDialogVisible:false,
                formData: null,
                fileListArr: [],
                saveVideoList:[],
                fileList: [],
                fromStatus: 1,
                i: 1,
                uploadIndex: null,
                saveStatus:false,//上传成功后保存视频
                taskId:null,//任务id
                taskStatus:null,
                taskDetails: {
                    nums:0,
                },
                preFix:null,
            }
        },
        created() {
            this.taskId = this.$route.query.id
            this.taskStatus = this.$route.query.status
            this.taskDetailsFn()
            this.preFix = api.url_prefix
        },
        methods: {
            taskDetailsFn(){
                this.$get({
                    url:api.pk_task_details,
                    data:{
                        id:this.taskId,
                    }
                }).then((res)=>{
                    this.taskDetails = res.data.info
                })
            },
            uploadFile(file) {
                // this.formDate.append('file', file.file)
                // console.log(this.formDate)
            },
            saveVideoFn(){
                if(!this.saveStatus){
                    this.$message({
                        message:'不满足保存条件',
                        type:'error'
                    })
                    return
                }
                this.$post({
                    url:api.save_videos,
                    data:{
                        id:this.taskId,
                        video_src:String(this.saveVideoList)
                    }
                }).then((res)=>{
                    if(res.data.code === 0){
                        this.$message({
                            message:"保存成功",
                            type:'success'
                        })
                        setTimeout(()=>{
                            this.$router.replace('/mission')
                        },1000)

                    }else if(res.data.code === 1){
                        this.$message({
                            message:res.data.msg.msg,
                            type:'warning'
                        })
                    }
                })
            },
            onChangeFn(file, fileList) {
                this.fileListArr[this.uploadIndex] = file
                this.$forceUpdate()
            },
            onProgress(index) {

                this.uploadIndex = index
            },
            // uploadPro(e,file,fileList){
            //     this.fileListArr[this.uploadIndex] = fileList[0]
            // },
            beforeUpload() {
                // if(this.fileListArr.length  != this.taskDetails.nums){
                //     this.$message({
                //         message:'只能上传'+this.taskDetails.nums+'个视频',
                //         type:'error'
                //     })
                //     return
                // }
                this.fileListArr.forEach((item, i) => {
                    item.status = '待上传'
                })
            },
            handleExceedFj(file, fileList) {
            },
            onChangeFj(file) {
                let currList = this.fileListArr
                for (let i=0;i<currList.length;i++){
                    // console.log(file.name==currList[i].name)
                    if(file.name==currList[i].name && file.size == currList[i].size){
                       this.$message({
                           message:'重复文件已自动过滤',
                           type:'error'
                       })
                        return
                    }
                }
                this.fileListArr.push(file)

            },
            handleRemoveFj() {

            },
            uploadFj() {

                if(this.fileListArr.length  != this.taskDetails.nums){
                    this.$message({
                        message:'只能上传'+this.taskDetails.nums+'个视频',
                        type:'error'
                    })
                    return
                }
                if(this.saveStatus){
                    this.$message({
                        message:'已上传完成，请保存',
                        type:'error'
                    })
                    return
                }

                if (!this.fromStatus) {
                    this.$message({
                        message:'上传中',
                        type:'error'
                    })
                    return
                }

                if (this.i > this.fileListArr.length) {
                    this.i = 1
                    return
                }
                this.fromStatus = 0
                this.$refs.scfj.submit();
                this.formDate = new FormData()
                // this.formDate.append('file', "12133")
                //let a = this.formDate.get('file')
                if (this.i > this.fileListArr.length) {
                    return
                }
                this.formDate.set('file', this.fileListArr[this.i - 1].raw)
                axios.post(api.url + "/api/common/upload_file", this.formDate, {
                    headers: {
                        "token": localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.saveVideoList.push(res.data.url)
                    this.fromStatus = 1
                    this.fileListArr[this.i - 1].status = '上传成功'
                    if(this.i == this.fileListArr.length){
                        this.saveStatus = true
                        this.$message({
                            message:'上传完成，请保存',
                            type:'success'
                        })
                        return
                    }
                    this.uploadFj(this.i++)

                }).catch(res => {

                })
            },
            again(index) {
                this.fileListArr[index].status = '重新上传'
                this.uploadFj(index, 0)
            },
            refreshVideoFn(){//强制刷新页面
                location.reload()
            }
        }
    }
</script>

<style scoped lang="scss">
    td {
        min-width: 200px;
        text-align: center;
        line-height: 64px;
        height: 64px;
    }

    th {
        min-width: 200px;
        text-align: center;
        height: 40px;
        line-height: 40px;
    }

    td:nth-child(1) {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .loading {
        padding: 20px;

        .loading-title {
            margin-bottom: 35px;
        }
    }

    .el-upload-list .el-upload-list--text {
        border: 2px solid;
    }

    .fileList {
        width: 100%;

        .fileList-tr {
            width: 100%;
        }
    }

    .fileList-btn {
        display: inline-block;
        padding: 0 15px;
        background-color: red;
        font-size: 12px;
        color: white;
        cursor: pointer;
        border-radius: 22px;
        height: 30px;
        line-height: 30px;
        margin: 0;
    }

    .fileList-btns {
        margin-right: 10px;
    }

    .winColor {
        color: #67c23a;
    }

    .elBtn {
        height: 32px;
        margin-left: 10px;
    }
    .title{
        margin-left: 40px;
        margin-top: 30px;
        color: #888888;
        font-size: 16px;
        line-height: 20px;
    }
    .goodsImg{
        width: 100px;
        height: 100px;
        /*border-radius: 50%;*/
        margin-top:40px;
        margin-left: 40px;
        img{
            width: 100%;
            height: 100%;
            /*border-radius: 50%;*/
        }
    }
</style>
