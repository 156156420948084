<template>
    <div>
        <div class="content-header">
           <div class="user">
               <div class="photo">
                    <img class="uheadimg" :src="url_prefix + userList.avatar"  alt="" v-if="userList.avatar">
               </div>
               <div class="user-account">
                   <p>
                       <span>账号：{{userList.username}}</span>
                       <!--<img class="user-class-two" src="../newweb/img/ffdr.png" alt="">-->
                   </p>
                   <div class="handle">
                       <p>余额：{{userList.wallet}}元</p>
                       <div style="display: flex;align-items: center">
                           <button class="btn-details" @click="$router.push('/deposit')">提现</button>
                           <button class="btn-details" @click="$router.push('/detail')">明细</button>
                       </div>
                   </div>
               </div>
                   <div class="time">
                   <p class="time-invitation">邀请码:{{userList.yqm}}</p>
                   <p>入驻时间：{{userList.create_time | dateFormat}}</p>
               </div>
           </div>
       </div>
<!--        <div class="tab">-->
<!--            <div class="tab-title">-->
<!--                <span class="title-p">出单情况</span>-->
<!--                <button class="tab-btn">刷新数据</button>-->
<!--            </div>-->
<!--            <div class="tab-main">-->
<!--                <table>-->
<!--                    <thead>-->
<!--                        <tr class="thead">-->
<!--                            <th class="tab-th">达人ID</th>-->
<!--                            <th class="tab-th">宝贝标题</th>-->
<!--                            <th class="tab-th">付款金额</th>-->
<!--                            <th class="tab-th">付款时间</th>-->
<!--&lt;!&ndash;                            <th class="tab-th">带货视频</th>&ndash;&gt;-->
<!--                            <th class="tab-th">订单状态</th>-->
<!--                        </tr>-->
<!--                        <tr class="thead-data" v-for="(item,index) in 10">-->
<!--                            <th class="tab-th-num">pk01</th>-->
<!--                            <th class="tab-th-num">飞机</th>-->
<!--                            <th class="tab-th-num">100</th>-->
<!--                            <th class="tab-th-num">2021-08-07</th>-->
<!--                            &lt;!&ndash;                            <th class="tab-th">带货视频</th>&ndash;&gt;-->
<!--                            <th class="tab-th-num">发货中</th>-->
<!--                        </tr>-->
<!--                    </thead>-->
<!--                </table>-->

<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
    import api from '../lnit/api'
    import unit from '../lnit/filter'
    export default {
        name: "user",
        data(){
            return{
                userList:{},
                url_prefix:null,
                photo:'',
            }
        },
        created() {
            this.getUserFn()
            this.url_prefix = api.url_prefix
        },
        methods:{
            getUserFn(){
                this.$post({
                    url:api.get_user,
                }).then((res)=>{
                    this.userList = res.data.user
                })
            }
        }
    }
</script>

<style scoped lang="scss">
     .content-header  {
        display: flex;
        font-size: 15px;
        color: #222;
        align-items: center;
         background-color: #fff;
    }
    .user{
        display: flex;
        font-size: 15px;
        color: #222;
        padding: 42px 70px;
        align-items: center;
        .uheadimg{
            width: 100px;
            height: 100px;
            border-radius: 50%;
        }

    }
    .handle{
        display: flex;
        align-items: center;
        .btn-details{
            display: inline-block;
            border:2px solid #FF9C66;
            color: #FF9C66;
            width: 48px;
            height: 20px;
            font-size: 11px;
            border-radius: 500px;
            outline: none;
            margin-left: 10px;
            background-color: #fff;
        }

    }
    .time{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 50px;
        height: 100px;
    }
    .tab{
        margin-top: 30px;
        padding: 20px;
        background-color: #fff;
        .tab-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #222222;
            font-size: 14px;
            border-left: 3px solid #555;
            padding-left:8px ;
            .title-p{
                margin: 0;
                font-width: 100;
            }
            .tab-btn{
                width: 80px;
                height: 24px;
                background-color: #4C94FF;
                border-radius: 2000px;
                color: #fff;
                font-size: 12px;
                border: none;
                outline: none;
            }
        }
        .tab-main{
            margin-top: 30px;
            height: 500px;
        }
    }
    .thead {
        background-color: #FAFAFA;
        .tab-th {
            min-width: 120px;
            text-align: center;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: rgb(153, 153, 153);
        }
    }
     .tab-th-num{
         min-width: 120px;
         font-style: normal;
         font-weight: normal;
         font-size: 12px;
         line-height: 34px;
     }
    .thead-data{
        background-color: #FAFAFA;
    }
    .photo{
        width: 100px;
        height: 100px;
        background: url("../assets/avatar.webp") no-repeat;
        background-size:100px 100px ;
        margin-right: 20px;
        border-radius: 50%;
        overflow: hidden;
    }
</style>
