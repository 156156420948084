import axios from 'axios'
import {Message} from "element-ui"
import api from './api'
let Url = api.url
// let Url = 'api'
import router from '../router/index'
// let create = true

const server = axios.create({
    url:Url,
    headers:{
        'Content-Type': 'application/json; charset=utf-8'
    },
    timeout:'5000',
    baseURL:'',
})

server.interceptors.request.use(function (config){
    if(localStorage.getItem("token")){
        config.headers.token = localStorage.getItem("token")
    }
    config.headers.from = 'pk'
    return config
}),function (err) {
    return Promise.reject(error)
}

server.interceptors.response.use(function(response){
    if(response.data.code == 999){
        localStorage.removeItem('token')
        router.push('/login')
        Message.error(response.data.msg)
    }
    return response
}),function (error) {
    return Promise.reject(error)
}

export function get(obj){
    return new Promise((resolve,reject)=>{
        server.get(Url + obj.url,{params:obj.data}).then((data)=>{
            resolve(data)
        })
    })
}
export function post(obj) {
    return new Promise((resolve,reject)=>{
        server.post(Url + obj.url ,obj.data).then((data)=>{
            resolve(data)
            if(data.data.code == 0){
                if(data.data.msg !== 'ok'){
                    Message.success(data.data.msg)
                }
            }else if(data.data.code == 1){
                Message.error(data.data.msg)
            }
        })
    })
}
