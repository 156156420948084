import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import mission from "../views/mission";
import user from "../views/user";
import detail from "../views/detail";
import saloon from "../views/saloon";
import deposit from "../views/deposit";
import loading from "../views/loading";
import login from "../views/login";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () =>import('../views/index'),
    redirect: '/',
    children: [
      {
        path: '/merchant',
        component: () => import('../views/merchant')
      },
      {
        path: '/',
        component: () => import('../views/content')
      },
      {
        path: '/login',
        name:'login',
        component: login
      },
      {
        path: '/intelligent',
        name:'login',
        component: () => import('../views/intelligent')
      }
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    redirect:'/user',
    children:[,
      {
        path:'/user',
        name:'user',
        component: user
      },
      {
        path:'/mission',
        name:'mission',
        component:mission
      },
      {
        path:'/detail',
        name:'detail',
        component: detail
      },
      {
        path: '/saloon',
        name:'saloon',
        component: saloon
      },
      {
        path: "/deposit",
        component: deposit
      },
      {
        path: '/loading',
        name:'loading',
        component: loading
      },
      {
        path: '/setUser',
        name:'setUser',
        component: () => import('../views/setUser')
      },
      {
        path:'/check',
        name:'check',
        component: ()=> import('../views/check')
      }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
