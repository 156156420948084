<template>
    <div>
        <div class="content-box">
            <div class="box-title">
            <span>
                任务
            </span>
            </div>
            <div class="box-nav">
                <div class="nav-btn">
                    <div class="refresh-btn"
                         :class="index === refreshIndex?'refresh-action':''"
                         v-for="(item,index) in btnList"
                         @click="refreshFn(index)"
                    >
                    <span>
                        {{item.text}}
                    </span>
                    </div>
                </div>
                <div class="nav-text">
                    接手限制:【{{getUserList.day_accept_nums}}】个&nbsp;&nbsp;本日已接:【{{todeyList}}】个&nbsp;&nbsp;剩余:【{{getUserList.day_accept_nums - todeyList}}】个
                </div>
            </div>
            <table class="tableborder" cellspacing="1" cellpadding="3" border="0" width="1109">
                <tbody>
                <tr>
                    <th class="tablerow2"><b>商品图片</b></th>
                    <th class="tablerow2"><b>商品标题</b></th>
                    <th class="tablerow2"><b>链接</b></th>
                    <th class="tablerow2"><b>任务信息</b></th>
                    <th class="tablerow2"><b>任务要求</b></th>
                     <th class="tablerow2"><b>接手时间</b></th>
                    <th class="tablerow2"><b>状态</b></th>

                    <th class="tablerow2"><b>操作</b></th>
                </tr>
                <tr style="text-align:center;border-bottom: 1px solid" v-for="(item,index) in taskListes">
                    <td class="tablerow1" width="154" height="100" rowspan="1">
                        <img :src="url_prefix + item.goods.img" style="max-width:100px;height: 100px" border="0"/>
                    </td>
                    <td class="tablerow1" style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">
                        <p>{{item.goods.title}}</p>
                        分类:{{goodsClassList[item.goods.class_id - 1].long_name}}
                    </td>
                    <td class="tablerow1" height="1" width="150" align="center"><a
                            :href="item.goods.link" target="_blank"
                            class="uibtn_blue">查看链接</a>
                    </td>
                    <td class="tablerow1"  style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">

                        <p>ID: {{item.id}}</p>
                        任务数量:{{item.nums}}
                    </td>
                    <td class="tablerow1" height="1" width="224" align="center">
                        <p
                           class="open-box uibtn_blue"
                           @click="dialogUpFn(item)"
                           >
                            查看亮点和要求
                        </p>
                    </td>
                     <td class="tablerow1" height="1" width="224" align="center">
                        <p> {{item.pk_js_time | dateFormat}}</p>
                    </td>
                    <td class="tablerow1" style="color:rgb(144, 147, 153)" height="" width="145" align="center">
                       <p v-if="item.status == 1">未发货</p>
                        <p v-if="item.status == 2">待上传</p>
                        <p v-if="item.status == 3">待审核</p>
                        <p v-if="item.status == 4">已分发</p>
                        <p v-if="item.status == 5">已完成</p>
                    </td>
                    <td class="tablerow1" height="" width="145" align="center" v-if="item.status == 2">
                        <el-button class="elbtn" type="primary" @click="newupvideo(item.id,item)">上传视频</el-button>
                        <p>
                            <el-button class="elbtn" type="primary" @click="checkSite(item)">查看快递</el-button>
                        </p>

                    </td>
                    <td class="tablerow1" height="" width="145" align="center" v-if="item.status == 3">

                        <el-button class="elbtn" type="primary" @click="newupvideo(item.id,item)">查看审核</el-button>
                        <el-button class="elbtn" style="margin:10px 0 0 0" type="primary" @click="repulseFn(item.id)">打回视频</el-button>
                    </td>

                </tr>
                </tbody>
            </table>
            <el-pagination
                class='videoPage'
                background
                layout="prev, pager, next"
                :total="videoTotal"
                @current-change="sizeChange"
                >
            </el-pagination>

        </div>
    <!--商家要求亮点提示-->
        <el-dialog
                title="提示"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
            <p class="btn">宝贝亮点</p><div style="padding:10px;font-size:14px;text-align:center;">{{liangdian}}</div>
            <p class="btn">宝贝要求</p><div style="padding:10px;font-size:14px;text-align:center;">{{pk_yaoqiu}}</div>
        </el-dialog>
        <el-dialog
                title="提示"
                :visible.sync="centerExpressVisible"
                width="30%"
                center>
            <p class="btn">快递名</p>
            <p style="text-align: center">{{itemObj.express_name}}</p>
            <p class="btn">快递单号</p>
            <p style="text-align: center">{{itemObj.express_number}}</p>
        </el-dialog>
        <el-dialog
                title="提示"
                :visible.sync="repulseShow"
                width="20%"
                :before-close="beforeFn">
            <span>确定打回</span>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="repulseShow = false">取 消</el-button>
                    <el-button type="primary" @click="repulseCanFn">确定</el-button>
                  </span>
        </el-dialog>

    </div>
</template>

<script>
    import api from '../lnit/api'
    export default {
        name: "mission",
        data(){
            return{
                getUserList:{
                    day_accept_nums:0,
                },
                todeyList:null,
                itemObj:{},
                refreshIndex:0,
                videoTotal:0,
                pk_yaoqiu:null,//商品要求
                liangdian:null,//亮点
                taskListes:null,
                goodsClassList:[
                    {
                        long_name:null,
                    }
                ],
                centerDialogVisible:false,
                centerExpressVisible:false,
                page: 1,
                btnList:[
                    {
                        text:'全部任务'
                    },
                    {
                        text:'未发货'
                    },
                    {
                        text:'待上传'
                    },
                    {
                        text:'待审核'
                    },
                    {
                        text:'已分发'
                    },
                    {
                        text:'已完成'
                    }
                ],
                url_prefix:null,
                repulseShow:false,
                repulseId:null,
            }
        },
        created() {
          this.myTaskListFn()
            this.url_prefix = api.url_prefix
            this.todeyAcceptFn()
        },
        methods:{
            todeyAcceptFn(){
                this.$get({
                    url:api.get_user
                }).then((res)=>{
                    this.getUserList = res.data.user
                })
                this.$get({
                    url:api.today_accept
                }).then((res)=>{
                    this.todeyList = res.data.nums
                })
            },
            myTaskListFn(){
                this.$get({
                    url:api.get_goods_class,
                }).then((res)=>{
                    this.goodsClassList = res.data.list
                })
                this.$get({
                    url:api.pk_my_task_list,
                    data:{
                        page:this.page,
                        status:this.refreshIndex==0?null:this.refreshIndex,
                    }
                }).then((res)=>{
                    this.taskListes = res.data.list.data
                    this.videoTotal = res.data.list.total
                })
            },
            sizeChange(e){
                this.page = e
                this.myTaskListFn()
            },
            dialogUpFn(item){
                this.centerDialogVisible = true
                this.pk_yaoqiu = item.pk_yaoqiu
                this.liangdian = item.liangdian
            },
            newupvideo(id,item){
                if(item.status == 2){
                    this.$router.push({path:'/loading',query: {id:id,status:item.status}})
                    console.log(item)
                }else if(item.status == 3){
                    this.$router.push({path:'/check',query: {id:id,status:item.status}})
                    console.log(item)
                }
            },
            repulseCanFn(){
                let data = {
                    id:this.repulseId
                }
                this.$post({
                    url:api.pk_re_up_task,
                    data:data
                }).then((res)=>{
                    if(res.data.code == 0){
                        this.$message({
                            message:res.data.msg,
                            type:'success'
                        })
                    }else if(res.data.code == 1){
                        this.$message({
                            message:res.data.msg,
                            type:'error'
                        })
                    }
                    this.repulseShow = false
                    this.page = 1
                    this.myTaskListFn()

                })
            },
            beforeFn(){
              this.repulseShow = false
            },
            repulseFn(id){//打回视频
                this.repulseShow = true
                this.repulseId = id
            },
            refreshFn(index){
                    this.refreshIndex = index
                    this.myTaskListFn()
            },
            checkSite(item){
                this.centerExpressVisible = true
                this.itemObj = item
            }
        }
    }
</script>

<style scoped lang="scss">
    a{
        text-decoration: none;
    }
    .content-box {
        width: 100%;
        padding: 20px;
        background-color: #fff;

        .box-title {
            text-align: left;
            font-size: 13px;
            margin-bottom: 35px;
        }

        .box-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;

            .nav-btn {
                display: flex;
                align-items: center;
                .refresh-btn{
                    position: relative;
                    color: red;
                    border: 0px;
                    padding: 4px 8px;
                    margin-right: 10px;
                    border-radius: 3px;
                    white-space: nowrap;
                    color: white;
                    background-color: rgb(144, 147, 153);
                    cursor: pointer;
                }
                .refresh-action{
                    background-color: #0099FF;
                    cursor: pointer;
                    position: relative;
                    border: 0px;
                    padding: 4px 8px;
                    margin-right: 10px;
                    border-radius: 3px;
                    white-space: nowrap;
                    color: white;
                }
            }
        }
    }


    .tableborder {
        border: 0px #8fb9d0 solid;
        border-radius: 2px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 5px;
        magin-right: 20px;
        font-size: 13px;
        margin-top: 20px;
    }

    .tableborder th, .minwidth {
        word-break: keep-all;
        white-space: nowrap;
        color: rgb(144, 147, 153);
        text-align: center;
        font-style: normal;
        font-weight: normal;
    }

    .uibtn_blue {
        display: inline-block;
        background: #0099FF;
        color: #fff;
        padding: 4px 8px;
        border-radius: 99px;
        font-size: 12px;
        white-space: nowrap;
        cursor: pointer;
    }
    .elbtn{
        font-size: 12px;
        padding: 5px 10px;
    }
    .videoPage{
        text-align:center;
    }
     .btn {
        position: relative;
        color: #fff;
        border: 0px;
        background:#0099FF;
        padding: 4px 8px;
        margin-left: 10px;
        border-radius: 3px;
        word-break: keep-all;
        white-space: nowrap;
        text-align:center;
        transition-duration: .8s;
    }
</style>
