<template>
    <div>
        <div class="content-box">
            <div class="box-title" style="display: flex;justify-content: space-between">
                <span>
                    任务
                </span>
                <div class="nav-text">
                    接手限制:【{{day_accept_nums}}】个&nbsp;&nbsp;本日已接:【{{todeyList}}】个&nbsp;&nbsp;剩余:【{{day_accept_nums - todeyList}}】个
                </div>

            </div>
<!--            <div class="box-nav">-->
<!--                <div class="nav-btn">-->
<!--                    <div class="refresh-btn btn" v-for="(item,index) in btnList">-->
<!--                    <span>-->
<!--                        {{item.text}}-->
<!--                    </span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="nav-text">-->
<!--                    接手限制：【200】单 本日已接：【0】单 剩余：【200】单-->
<!--                </div>-->
<!--            </div>-->

            <table class="tableborder" cellspacing="1" cellpadding="3" border="0" width="1109">
                <tbody>
                <tr>
                    <th class="tablerow2"><b>商品图片</b></th>
                    <th class="tablerow2"><b>商品标题</b></th>
                    <th class="tablerow2"><b>链接</b></th>
                    <th class="tablerow2"><b>任务信息</b></th>
                    <th class="tablerow2"><b>任务要求</b></th>
                    <th class="tablerow2"><b>操作</b></th>
                </tr>
                    <tr style="text-align:center;border-bottom: 1px solid" v-for="(item,index) in taskList">
                        <td class="tablerow1" width="154" height="100" rowspan="1">
                            <img :src="url_prefix + item.goods.img" style="max-width:100px;height: 100px" border="0"/>
                        </td>
                        <td class="tablerow1" style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">
                            <p>{{item.goods.title}}</p>
                            分类:{{goodsClassList[item.goods.class_id - 1].long_name}}
                        </td>
                    <td class="tablerow1" height="1" width="150" align="center"><a
                            href="https://detail.tmall.com/item.htm?id=623472566007" target="_blank"
                            class="uibtn_blue">查看链接</a>
                    </td>
                    <td class="tablerow1"  style="color:rgb(144, 147, 153);width: 200px;min-height: 100px">

                       <p>ID: {{item.id}}</p>
                        任务数量:{{item.nums}}
                    </td>
                    <td class="tablerow1" height="1" width="224" align="center">
                        <p
                                class=" uibtn_blue"
                                @click="dialogUpFn(item)"
                        >
                            查看亮点和要求
                        </p>
                    </td>
                    <td class="tablerow1" height="" width="145" align="center">
                        <el-button class="elbtn" type="primary" @click="takeOverFn(item.id)">立即接手</el-button>
                    </td>
                </tr>
                </tbody>
            </table>
             <el-pagination
                style="text-align:center;"
                background
                layout="prev, pager, next"
                :total="videoTotal"
                @current-change="sizeChange"
                >
            </el-pagination>
        </div>
        <el-dialog
                title="提示"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
            <p class="btn">宝贝亮点</p><div style="padding:10px;font-size:14px;text-align:center;">{{liangdian}}</div>
            <p class="btn">宝贝要求</p><div style="padding:10px;font-size:14px;text-align:center;">{{pk_yaoqiu}}</div>
        </el-dialog>

    </div>
</template>

<script>
    import api from "../lnit/api"
    export default {
        name: "saloon",
        data(){
            return{
                page:1,
                taskList:[],//大厅列表
                btnList:[
                    {
                        text:'全部任务'
                    },
                    {
                        text:'已接手'
                    },
                    {
                        text:'待上传'
                    },
                    {
                        text:'待通过'
                    },
                    {
                        text:'仲裁中'
                    },
                    {
                        text:'已完成'
                    }
                ],
                url_prefix:null,
                goodsClassList:null,//商品分类
                centerDialogVisible:false,
                pk_yaoqiu:null,//要求
                liangdian:null,//亮点
                videoTotal:null,
                day_accept_nums:0,
                todeyList:0,
            }
        },
        created() {
            this.taskListFn()
            this.url_prefix = api.url_prefix
        },
        methods:{
            dialogUpFn(item){//亮点和要求
                this.centerDialogVisible = true
                this.pk_yaoqiu = item.pk_yaoqiu
                this.liangdian = item.liangdian
            },
            takeOverFn(id){
                this.$post({
                    url:api.accept_task,
                    data:{
                        id:id
                    }
                }).then((res)=>{
                    if(res.data.code == 1){
                        this.$message({
                            message:res.data.msg.msg,
                            type:'error'
                        })
                    }else if(res.data.code === 0){
                        this.$message({
                            message:'接手成功',
                            type:'success'
                        })
                        this.taskListFn()
                        // this.$router.push('/mission')
                    }
                })
            },
            sizeChange(e){
                this.page = e
                this.taskListFn()
            },
            taskListFn(){
                this.$get({
                    url:api.get_goods_class,
                }).then((res)=>{
                    this.goodsClassList = res.data.list
                })
                this.$get({
                    url:api.pk_task_list,
                    data:{
                        page:this.page,
                    }
                }).then((res)=>{
                   this.taskList = res.data.list.data
                   this.videoTotal = res.data.list.total
                })
                this.$get({
                    url:api.get_user
                }).then((res)=>{
                    this.day_accept_nums = res.data.user.day_accept_nums
                })
                this.$get({
                    url:api.today_accept
                }).then((res)=>{
                    this.todeyList = res.data.nums
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    a{
        text-decoration: none;
    }
    .content-box {
        width: 100%;
        padding: 20px;
        height: 1000px;
        background-color: #fff;

        .box-title {
            text-align: left;
            font-size: 13px;
            margin-bottom: 35px;
        }

        .box-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;

            .nav-btn {
                display: flex;
                align-items: center;
                .btn {
                    position: relative;
                    color: #fff;
                    background: linear-gradient(90deg, #6245D3, #E524D6);
                    border: 0px;
                    padding: 4px 8px;
                    margin-right: 10px;
                    border-radius: 3px;
                    word-break: keep-all;
                    white-space: nowrap;
                }
            }
        }
    }


    .tableborder {
        border: 0px #8fb9d0 solid;
        border-radius: 2px;
        width: 100%;
        margin-top: 0;
        margin-bottom: 5px;
        magin-right: 20px;
        font-size: 13px;
        margin-top: 20px;
    }

    .tableborder th, .minwidth {
        word-break: keep-all;
        white-space: nowrap;
        color: rgb(144, 147, 153);
        text-align: center;
        font-style: normal;
        font-weight: normal;
    }

    .uibtn_blue {
        display: inline-block;
        background: #0099FF;
        color: #fff;
        padding: 4px 8px;
        border-radius: 99px;
        font-size: 12px;
        word-break: keep-all;
        white-space: nowrap;
        text-decoration: none;
    }
    .btn {
        position: relative;
        color: #fff;
        /*background: #8C45D3;*/
        background:#0099FF;
        text-align:center;
        border: 0px;
        padding: 4px 8px;
        margin-left: 10px;
        border-radius: 3px;
        word-break: keep-all;
        white-space: nowrap;
        transition-duration: .8s;
    }
    .elbtn{
        font-size: 12px;
        padding: 5px 10px;
    }
    .tablerow2{
        text-align: center;
    }
</style>
