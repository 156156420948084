<template>
    <div class="with-page">
        <div class="pay-log-top">
            <a href="/user" class="index">
                <p>首页</p>
            </a>
            <img class="pay-img" src="../assets/arr-right.png" alt="">
            <a href="/deposit" class="index">
                <p>提现</p>
            </a>
        </div>
        <!-- 提现账号 -->
        <div class="with-handle">
            <h4>您当前余额为： <span class="handle-num">{{userList.wallet}}</span>元</h4>
            <p class="hint">提现手续费为3%</p>
                <el-radio v-model="radio" label="1" @change="redioChange">支付宝</el-radio>
                <el-radio v-model="radio" label="2"  @change="redioChange">微信</el-radio>
            <div v-if="redioShow">
                <div v-if="!userList.zfb_number">
                    <p class="account-item">
                        <span>绑定支付宝账号：</span>
                        <input type="text" name="zfb" size="20" class="inputstyle" v-model="infoList.zfb_number" maxlength="99">
                    </p>
                    <p class="account-item">
                        <span>绑定支付宝姓名：</span>
                        <input type="text" name="realname" size="20" class="inputstyle" v-model="infoList.zfb_name"  onkeyup="value=value.replace(/[ -~]/g,'')" onkeydown="if(event.keyCode==13)event.keyCode=9">
                    </p>
                    <button class="sub-with" @click="submit">提交</button>
                </div>
                <div class="account-list" v-if="userList.zfb_number">
                    <p class="account-item">
                        <span>提现金额：</span>
                        <input type="text" name="moneys" id="moneys" size="20" class="inputstyle" v-model="tx_price" maxlength="12" onkeyup="value=value.replace(/[^\d]/g,'');realmoneys();">
                        <span class="unit">元</span>
                    </p>
                    <p class="account-item">
                        <span>支付宝账号：</span>
                        <input type="text" name="zfb" size="20" class="inputstyle" readonly="readonly" :value="userList.zfb_number" maxlength="99">
                    </p>
                    <p class="account-item">
                        <span>支付宝姓名：</span>
                        <input type="text" name="realname" size="20" class="inputstyle" readonly="readonly" :value="userList.zfb_name" maxlength="4" onkeyup="value=value.replace(/[ -~]/g,'')" onkeydown="if(event.keyCode==13)event.keyCode=9">
                    </p>
                </div>
                <button class="sub-with" v-if="userList.zfb_number" @click="txDetaiFn">确认提现</button>
            </div>
            <div v-if="!redioShow" style="margin-top: 20px">
                <div v-if="!userList.wx_img">
                    <el-upload
                            :file-list="fileListArr"
                            action="https://app.xysjzyx.com/api/common/upload_file"
                            accept=".png,.jpg"
                            ref="scfj"
                            :show-file-list="false"
                            multiple
                            drag
                            :auto-upload="false"
                            :on-change="onChangeFj"
                    >
                        <div style="display: flex;justify-content: center;align-items: center;height: 100%;">
                             <span style="color: #999999">
                                上传微信二维码
                            </span>
                        </div>

                    </el-upload>
                </div>
<!--                <div v-if="userList.wx_img" style="width: 200px;height: 200px;display: flex;flex-direction: column;align-items: center">-->
<!--                    <img style="width: 200px;margin-bottom: 10px; height: 200px;" :src="preFix + userList.wx_img" alt="">-->
<!--                    <span style="color: #999999;">-->
<!--                        已绑定-->
<!--                    </span>-->
<!--                </div>-->
                  <div v-if="userList.wx_img">
                      <p class="account-item">
                          <span>提现金额：</span>
                          <input type="text" name="moneys"  size="20" class="inputstyle" v-model="tx_price" maxlength="12" onkeyup="value=value.replace(/[^\d]/g,'');realmoneys();">
                      </p>
                      <button class="sub-with" @click="txDetaiFn">确认提现</button>
                  </div>
            </div>

        </div>
        <!-- 提现记录 -->
        <div class="with-list">
            <h3>提现记录</h3>
            <table border="0" role="rows" class="tab">
                <tbody>
                    <tr class="head">
                        <th class="head-th">时间</th>
                        <th class="head-th">提现金额</th>
                        <th class="head-th">状态</th>
                    </tr>
                    <tr class="rows" v-for="(item,index) in txDetailList">
                        <td class="rows-th">{{item.create_time | dateFormat}}</td>
                        <td class="rows-th">
                            {{item.tx_money}} 元
                        </td>
                        <td class="reject rows-th">
                            <p v-if="item.status == 0" style="color: #888888">审核中</p>
                            <p class="reslove" v-if="item.status == 1">提现成功</p>
                            <p v-if="item.status == 2">
                                <span style="color: red">提现失败</span>
                                <br>
                                <span style="font-size: 12px;color: #888888">
                                    {{item.reason}}
                                </span>

                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <el-pagination
                    class='videoPage'
                    background
                    layout="prev, pager, next"
                    :total="videoTotal"
                    @current-change="sizeChange"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import api from '../lnit/api'
    import axios from "axios";
    export default {
        name: "deposit",
        data(){
            return{
                formDate:null,
                zfb_number:null,
                zfb_name:null,
                hintStatus:false,
                userList:{
                    wx_img:null,
                    zfb_name:null,
                    zfb_number:null,
                },
                infoList:{
                    zfb_name:null,
                    zfb_number:null,
                },
                txDetailList:null,
                tx_price:null,
                txPage:1,
                videoTotal:null,
                radio:'1',
                fileListArr:[],
                preFix:null,
                redioShow:true,
            }
        },
        created() {
            this.txPage =1
            this.getUserFn()
            this.txRecordFn()
            this.preFix = api.url_prefix
        },
        methods:{
            txRecordFn(){
              this.$get({
                  url:api.tx_record,
                  data:{
                      page:this.txPage
                  },
              }).then((res)=>{
                    this.txDetailList = res.data.list.data
                  this.videoTotal = res.data.list.total
              })
            },
            getUserFn(){
              this.$post({
                  url:api.get_user,
              }).then((res)=>{
                  this.userList = res.data.user
              })
            },
            beforeUpload(file){
                console.log(file)
            },
            uploadFile(file){
                this.$refs.scfj.submit();
                this.formDate = new FormData()
                // this.formDate.append('file', "12133")
                //let a = this.formDate.get('file')
                this.formDate.set('file', this.fileListArr[0])
                axios.post(api.url + api.bind_wx, this.formDate, {
                    headers: {
                        "token": localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(res => {
                    this.fromStatus = 1
                    this.$message({
                        message:'上传成功',
                        type:'success'
                    })
                    this.getUserFn()
                }).catch(res => {

                })
            },
            onChangeFj(file){
                this.fileListArr.push(file.raw)
                this.uploadFile()
            },

            submit(){
                this.$post({
                    url:api.bind_alipay,
                    data:{
                        zfb_name:this.infoList.zfb_name,
                        zfb_number:this.infoList.zfb_number
                    }
                }).then((res)=>{
                    this.hintStatus = true
                    if(res.data.code == 0){
                        this.$message({
                            message:res.data.msg,
                            type:'success'
                        })
                        this.getUserFn()
                    }else if(res.data.code === 1){
                        this.$message({
                            message:res.data.msg,
                            type:'error'
                        })
                    }
                })
            },
            sizeChange(e){
                this.txPage = e
                this.txRecordFn()
            },
            redioChange(e){
                if(e == 1){
                    this.redioShow = true
                }else{
                    this.redioShow = false
                }
            },
            txDetaiFn(){
                if(this.radio == 1){
                    if(!this.tx_price){
                        this.$message({
                            message:'请输入提现记录',
                            type:'error',
                        })
                        return
                    }
                }
                this.$post({
                    url:api.do_tx,
                    data:{
                        money:this.tx_price,
                        type:this.radio
                    }
                }).then((res)=>{
                  if(res.data.code == 0){
                        this.txRecordFn()
                      this.getUserFn()
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .with-page > .pay-log-top {
        display: flex;
        align-items: center;
        height: 60px;
        padding-left: 20px;
        color: #AAAAAA;
        font-size: 14px;
        border-bottom: 1px solid #F4F4F4;
        background-color: #fff;
    }
    .pay-log-top > a{
        text-decoration: none;
        color: rgb(76, 148, 255);
    }
    .pay-img{
        width: 5px;
        height: 8px;
        margin:  0 6px;
    }
    .with-handle h4{
        color: #222222;
        font-size: 16px;
        font-weight: 400;
        margin: 0;
    }
    .handle-num{
        color: #FF5050;
    }
    .hint{
        color: #FF5050;
        font-size: 12px;
    }
    .account-list{
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px;
        justify-content: space-between;
    }
    .account-item{
        position: relative;
        align-items: center;
        .inputstyle{
            width: 184px;
            height: 36px;
            border: 1px solid #DEDEDE;
            border-radius: 3px;
            box-sizing: border-box;
        }
        .inputstyle:focus{
            outline: none;
        }
        .unit{
            color: #999999;
            font-size: 13px;
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .sub-with{
        background-color: #4C94FF;
        border-radius: 5200px;
        color: #fff;
        font-size: 14px;
        border: none;
        outline: none;
        width: 132px;
        height: 36px;
    }
    .with-handle{
        background-color: #fff;
        padding: 40px;
    }
    .with-list{
        margin-top: 20px;
        background-color: #fff;
        padding: 20px;
        h3{
            font-weight: 400;
            font-size: 15px;
        }
         .tab .head {
             width: 100%;
            background-color: #F5F5F5;
            color: #999999;
            font-size: 11px;
            height: 36px;
             .head-th{
                 width: 33.333%;
             }
        }
        .rows{
            .rows-th{
                text-align: center;
                font-size: 12px;
            }
            .reslove{
                color: #33B858;
            }
        }
    }
    .tab{
        width:100% ;
    }
    .videoPage{
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
</style>
