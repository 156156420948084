<template>
    <div>
        <div class="content">
            <div class="video">
                <div>
                    <div class="video-nav">

<!--                        <div class="title">-->
<!--                            <span>二, 拍摄视频</span>-->
<!--                        </div>-->
                        <ul class="nav-ul">
                            <li class="nav-title"
                                 :class="navIndex == 1?'nav-action':'nav-color'"
                                 @click="navClickSkipFn"
                            >
                                <span>首页概况</span>
                            </li>
                            <li class="nav-li"
                                :class="navIndex == 2?'nav-action':'nav-color'"
                                @click="saloonSkipFn"
                            >
                                <span class="nav-a">
                                    拍客大厅
                                </span>
                            </li>
                            <li class="nav-li"
                                :class="navIndex == 3?'nav-action':'nav-color'"
                                @click="missionSkipFn"
                            >
                                <span class="nav-a" to="">
                                    任务管理
                                </span>
                            </li>
                        </ul>
                    </div>
                 </div>
                <div class="video-content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import mission from "./mission";
    export default {
        components: {
            // mission
        },
        name: "content",
        data() {
            return {
                navList: [
                    {
                        text: '拍客大厅',
                        to: '',
                    },
                    {
                        text: '任务管理',
                        to: '',
                    }
                ],
                navIndex: 1,
            }
        },
        created(){
            console.log(this.$route.path)
            if(this.$route.path == '/mission'){
                this.navIndex = 3
            }else if(this.$route.path == '/saloon'){
                this.navIndex = 2
            }else if(this.$route.path == '/user'){
                this.navIndex = 1
            }
        },
        methods: {
            navClickFn(index) {
                this.navIndex = index
            },
            navClickSkipFn(){
                this.navIndex = 1
                this.$router.push('/user')
            },
            saloonSkipFn(){
                this.navIndex = 2
                this.$router.push('/saloon')
            },
            missionSkipFn(){
                this.navIndex = 3
                this.$router.push('/mission')
            }
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
    }
    .content {
        width: 100%;
        min-height: calc(100vh - 80px);
        background-color: #F6F8FA;
        padding: 1px 0;
        box-sizing: border-box;

        .video {
            display: flex;
            width: 1200px;
            margin: 50px auto;
        }

        .video-nav {
            width: 185px;
            margin-right: 20px;
            background-color: #fff;
            .nav-title {
                position: relative;
                padding: 15px 20px;
                font-size: 13px;
                text-align: left;
                cursor: pointer;
                list-style: none;
            }

            /*.nav-title::after {*/
            /*    position: absolute;*/
            /*    left: 0;*/
            /*    top: 10px;*/
            /*    content: '';*/
            /*    display: block;*/
            /*    width: 3px;*/
            /*    height: 28px;*/
            /*    background-color: #4C94FF;*/
            /*}*/

            .title {
                font-size: 15px;
                color: red;
                text-align: left;
                padding: 10px 15px;
            }

            .nav-ul {
                padding: 0;
                margin: 0;
                .nav-li {
                    list-style: none;
                    text-align: left;
                    padding: 15px 20px;
                    font-size: 14px;
                    cursor: pointer;
                }


            }
        }
    }

    .video-content {
        width: 100%;
    }

    .content-box {
        width: 100%;
        padding: 20px;
        height: 1000px;
        background-color: #fff;

        .box-title {
            text-align: left;
            font-size: 13px;
            margin-bottom: 35px;
        }

        .box-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;

            .nav-btn {
                display: flex;
                align-items: center;

                .btn {
                    position: relative;
                    color: #fff;
                    background: linear-gradient(90deg, #6245D3, #E524D6);
                    border: 0px;
                    padding: 4px 8px;
                    margin-right: 10px;
                    border-radius: 3px;
                    word-break: keep-all;
                    white-space: nowrap;
                }
            }
        }
    }

    .tableborder {
        border-radius: 2px;
        width: 100%;
        margin-bottom: 5px;
        magin-right: 20px;
        font-size: 13px;
        margin-top: 20px;
    }

    .tableborder th, .minwidth {
        background: #BB9AE9;
        word-break: keep-all;
        white-space: nowrap;
        color: rgb(144, 147, 153);
        text-align: center;
        font-style: normal;
        font-weight: normal;
    }
    .content-header {
        width: 995px;
        background-color: #fff;
        display: flex;
        align-items: center;
        border-radius: 3px;
    }
    .nav-action {
        color: #4C94FF;
        position: relative;
        border-left: 3px solid #4C94FF;
    }
    /*.nav-action::after {*/
    /*    position: absolute;*/
    /*    left: 0;*/
    /*    top: 12px;*/
    /*    content: '';*/
    /*    display: block;*/
    /*    width: 3px;*/
    /*    height: 20px;*/
    /*    background-color: #4C94FF;*/
    /*}*/
    .nav-color{
        color: #888888;
        border-left: 3px solid #ffffff;
    }
</style>
